var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("van-nav-bar", {
        attrs: { title: "市场行情", placeholder: "", fixed: "", border: false }
      }),
      _c(
        "div",
        { staticClass: "back" },
        [
          _c("van-sticky", [
            _c("div", { staticClass: "timeleft" }, [
              _c("div", [_vm._v(_vm._s(_vm.timecenter))]),
              _c(
                "div",
                {
                  staticClass: "timeright",
                  style: {
                    color: _vm.opentrade == true ? "#02790D" : "#CC0E0F"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.opentrade == true ? "开盘中" : "已停盘") +
                      " "
                  )
                ]
              )
            ])
          ])
        ],
        1
      ),
      _vm.notices.enable
        ? _c(
            "div",
            { staticClass: "stock-price-info" },
            [
              _c("img", {
                staticStyle: { width: "40px", height: "32px" },
                attrs: {
                  src: require("@/assets/images/icon_notice@2x.png"),
                  alt: ""
                }
              }),
              _c(
                "notice-bar",
                { attrs: { width: 500 } },
                _vm._l(_vm.notices.notices, function(items) {
                  return items.enable
                    ? _c("span", { staticStyle: { "margin-right": "50px" } }, [
                        _vm._v(" " + _vm._s(items.content) + " ")
                      ])
                    : _vm._e()
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _vm.havedata
            ? _c("div", { staticClass: "totalwidth" }, [
                _c(
                  "div",
                  { staticClass: "productitems" },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("span", { staticClass: "headerBg priceBg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("现货价格")])
                    ]),
                    _c("ul", { staticClass: "flex ulflex topul" }, [
                      _c("li", { staticStyle: { flex: "2" } }, [
                        _vm._v("商品")
                      ]),
                      _c("li", { staticClass: "navleft" }, [_vm._v("回购")]),
                      _c("li", { staticClass: "navright" }, [_vm._v("销售")]),
                      _c("li", [_vm._v("高/低")])
                    ]),
                    _vm._l(_vm.arrcenter.spotPrice, function(items, index) {
                      return _c(
                        "div",
                        { key: items.engname, staticClass: "ulcenter" },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "flex ulflex productlist",
                              class:
                                index == _vm.arrcenter.huangjin.length - 1
                                  ? "lastul"
                                  : index == 0
                                  ? "firstul"
                                  : ""
                            },
                            [
                              _c("li", { staticClass: "flex_product" }, [
                                _vm._v(_vm._s(items.engname))
                              ]),
                              _vm.sendmsg
                                ? [
                                    _c(
                                      "li",
                                      {
                                        // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                        class:
                                          //   'huigou'
                                          // ] <= items.productData['huigou']
                                          //   ? 'redfont'
                                          //   : 'greenfont'
                                          items.productData["huigouColor"]
                                      },
                                      [
                                        items.productData["hgDisplay"] == "SHOW"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "changeTwoDecimal_x"
                                                    )(
                                                      items.productData[
                                                        "huigou"
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : [_vm._v(" - ")]
                                      ],
                                      2
                                    ),
                                    _c(
                                      "li",
                                      {
                                        // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                        class:
                                          //   'xiaoshou'
                                          // ] <= items.productData['xiaoshou']
                                          //   ? 'redfont'
                                          //   : 'greenfont'
                                          items.productData["huigouColor"]
                                      },
                                      [
                                        items.productData["xsDisplay"] == "SHOW"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "changeTwoDecimal_x"
                                                    )(
                                                      items.productData[
                                                        "xiaoshou"
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : [_vm._v(" - ")]
                                      ],
                                      2
                                    ),
                                    _c("li", { staticClass: "lastli" }, [
                                      _c(
                                        "div",
                                        {
                                          // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                          class:
                                            //   'high'
                                            // ] <= items.productData['high']
                                            //   ? 'redfont'
                                            //   : 'greenfont'
                                            items.productData["huigouColor"]
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_x")(
                                                  items.productData["high"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                          class:
                                            //   'low'
                                            // ] <= items.productData['low']
                                            //   ? 'redfont'
                                            //   : 'greenfont'
                                            items.productData["huigouColor"]
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_x")(
                                                  items.productData["low"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                : [
                                    _c(
                                      "li",
                                      { staticClass: "redfont" },
                                      [
                                        items.productData["hgDisplay"] == "SHOW"
                                          ? [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "changeTwoDecimal_x"
                                                      )(
                                                        items.productData[
                                                          "huigou"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          : [_vm._v(" - ")]
                                      ],
                                      2
                                    ),
                                    _c(
                                      "li",
                                      { staticClass: "redfont" },
                                      [
                                        items.productData["xsDisplay"] == "SHOW"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "changeTwoDecimal_x"
                                                    )(
                                                      items.productData[
                                                        "xiaoshou"
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : [_vm._v(" - ")]
                                      ],
                                      2
                                    ),
                                    _c("li", { staticClass: "lastli" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["high"]
                                            )
                                          )
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["low"]
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                            ],
                            2
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "productitems" },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("span", { staticClass: "headerBg priceBg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("国内行情")])
                    ]),
                    _c("ul", { staticClass: "flex ulflex topul" }, [
                      _c("li", { staticStyle: { flex: "2" } }, [
                        _vm._v("商品")
                      ]),
                      _c("li", { staticClass: "navleft" }, [_vm._v("回购")]),
                      _c("li", { staticClass: "navright" }, [_vm._v("销售")]),
                      _c("li", [_vm._v("高/低")])
                    ]),
                    _vm._l(_vm.arrcenter.shangjinsuo, function(items, index) {
                      return _c(
                        "div",
                        {
                          key: items.productData["name"],
                          staticClass: "ulcenter"
                        },
                        [
                          items.productData["display"] == "SHOW"
                            ? _c(
                                "ul",
                                {
                                  staticClass: "flex ulflex productlist",
                                  class:
                                    index ==
                                    _vm.arrcenter.shangjinsuo.length - 1
                                      ? "lastul"
                                      : index == 0
                                      ? "firstul"
                                      : ""
                                },
                                [
                                  _c("li", { staticClass: "flex_product" }, [
                                    _vm._v(
                                      _vm._s(
                                        items.productData["name"] == "黄金99.99"
                                          ? "黄金9999"
                                          : items.productData["name"] ==
                                            "黄金99.95"
                                          ? "黄金9995"
                                          : items.productData["name"]
                                      )
                                    )
                                  ]),
                                  _vm.sendmsg
                                    ? [
                                        _c(
                                          "li",
                                          {
                                            // new_tuisong_val_before['sge'].prices[items['engname']][
                                            class:
                                              //   'huigou'
                                              // ] <= items.productData['huigou']
                                              //   ? 'redfont'
                                              //   : 'greenfont'
                                              items.productData["huigouColor"]
                                          },
                                          [
                                            items.productData["hgDisplay"] ==
                                            "SHOW"
                                              ? [
                                                  items.productData["name"] ==
                                                  "白银(T+D)"
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              items.productData[
                                                                "huigou"
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "changeTwoDecimal_f"
                                                              )(
                                                                items
                                                                  .productData[
                                                                  "huigou"
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ]
                                              : [_vm._v(" - ")]
                                          ],
                                          2
                                        ),
                                        _c(
                                          "li",
                                          {
                                            // new_tuisong_val_before['sge'].prices[items['engname']][
                                            class:
                                              //   'xiaoshou'
                                              // ] <= items.productData['xiaoshou']
                                              //   ? 'redfont'
                                              //   : 'greenfont'
                                              items.productData["huigouColor"]
                                          },
                                          [
                                            items.productData["xsDisplay"] ==
                                            "SHOW"
                                              ? [
                                                  items.productData["name"] ==
                                                  "白银(T+D)"
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              items.productData[
                                                                "xiaoshou"
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "changeTwoDecimal_f"
                                                              )(
                                                                items
                                                                  .productData[
                                                                  "xiaoshou"
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ]
                                              : [_vm._v(" - ")]
                                          ],
                                          2
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "lastli" },
                                          [
                                            items.productData["name"] ==
                                            "白银(T+D)"
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      // new_tuisong_val_before['sge'].prices[items['engname']][
                                                      class:
                                                        //   'high'
                                                        // ] <= items.productData['high']
                                                        //   ? 'redfont'
                                                        //   : 'greenfont'
                                                        items.productData[
                                                          "huigouColor"
                                                        ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            items.productData[
                                                              "high"
                                                            ]
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      // new_tuisong_val_before['sge'].prices[items['engname']][
                                                      class:
                                                        //   'low'
                                                        // ] <= items.productData['low']
                                                        //   ? 'redfont'
                                                        //   : 'greenfont'
                                                        items.productData[
                                                          "huigouColor"
                                                        ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            items.productData[
                                                              "low"
                                                            ]
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _c(
                                                    "div",
                                                    {
                                                      // new_tuisong_val_before['sge'].prices[items['engname']][
                                                      class:
                                                        //   'high'
                                                        // ] <= items.productData['high']
                                                        //   ? 'redfont'
                                                        //   : 'greenfont'
                                                        items.productData[
                                                          "huigouColor"
                                                        ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "changeTwoDecimal_f"
                                                            )(
                                                              items.productData[
                                                                "high"
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      // new_tuisong_val_before['sge'].prices[items['engname']][
                                                      class:
                                                        //   'low'
                                                        // ] <= items.productData['low']
                                                        //   ? 'redfont'
                                                        //   : 'greenfont'
                                                        items.productData[
                                                          "huigouColor"
                                                        ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "changeTwoDecimal_f"
                                                            )(
                                                              items.productData[
                                                                "low"
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      ]
                                    : [
                                        _c(
                                          "li",
                                          { staticClass: "redfont" },
                                          [
                                            items.productData["hgDisplay"] ==
                                            "SHOW"
                                              ? [
                                                  items.productData["name"] ==
                                                  "白银(T+D)"
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              items.productData[
                                                                "huigou"
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "changeTwoDecimal_f"
                                                              )(
                                                                items
                                                                  .productData[
                                                                  "huigou"
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ]
                                              : [_vm._v(" - ")]
                                          ],
                                          2
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "redfont" },
                                          [
                                            items.productData["xsDisplay"] ==
                                            "SHOW"
                                              ? [
                                                  items.productData["name"] ==
                                                  "白银(T+D)"
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              items.productData[
                                                                "xiaoshou"
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "changeTwoDecimal_f"
                                                              )(
                                                                items
                                                                  .productData[
                                                                  "xiaoshou"
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ]
                                              : [_vm._v(" - ")]
                                          ],
                                          2
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "lastli" },
                                          [
                                            items.productData["name"] ==
                                            "白银(T+D)"
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        items.productData[
                                                          "high"
                                                        ]
                                                      )
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        items.productData["low"]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              : [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "changeTwoDecimal_f"
                                                          )(
                                                            items.productData[
                                                              "high"
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_f"
                                                        )(
                                                          items.productData[
                                                            "low"
                                                          ]
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                          ],
                                          2
                                        )
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "productitems" },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("span", { staticClass: "headerBg internationalBg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("国际行情")])
                    ]),
                    _c("ul", { staticClass: "flex ulflex topul" }, [
                      _c("li", { staticStyle: { flex: "2" } }, [
                        _vm._v("商品")
                      ]),
                      _c("li", { staticClass: "navleft" }, [_vm._v("回购")]),
                      _c("li", { staticClass: "navright" }, [_vm._v("销售")]),
                      _c("li", [_vm._v("高/低")])
                    ]),
                    _vm._l(_vm.arrcenter.guojixianhuo, function(items, index) {
                      return _c(
                        "div",
                        {
                          key: items.productData["name"],
                          staticClass: "ulcenter"
                        },
                        [
                          items.productData["display"] == "SHOW"
                            ? _c(
                                "ul",
                                {
                                  staticClass: "flex ulflex productlist",
                                  class:
                                    index ==
                                    _vm.arrcenter.guojixianhuo.length - 1
                                      ? "lastul"
                                      : index == 0
                                      ? "firstul"
                                      : ""
                                },
                                [
                                  _c("li", { staticClass: "flex_product" }, [
                                    _vm._v(_vm._s(items.productData["name"]))
                                  ]),
                                  _vm.sendmsg
                                    ? [
                                        _c(
                                          "li",
                                          {
                                            // new_tuisong_val_before['international'].prices[
                                            class:
                                              //   items['engname']
                                              // ]['huigou'] <= items.productData['huigou']
                                              //   ? 'redfont'
                                              //   : 'greenfont'
                                              items.productData["huigouColor"]
                                          },
                                          [
                                            items.productData["name"] ==
                                              "伦敦金" ||
                                            items.productData["name"] ==
                                              "伦敦钯" ||
                                            items.productData["name"] ==
                                              "伦敦铂"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_f"
                                                        )(
                                                          items.productData[
                                                            "huigou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : items.productData["name"] ==
                                                  "伦敦银" ||
                                                items.productData["name"] ==
                                                  "美元指数"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_fthree"
                                                        )(
                                                          items.productData[
                                                            "huigou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        items.productData[
                                                          "huigou"
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                          ],
                                          2
                                        ),
                                        _c(
                                          "li",
                                          {
                                            // new_tuisong_val_before['international'].prices[
                                            class:
                                              //   items['engname']
                                              // ]['xiaoshou'] <= items.productData['xiaoshou']
                                              //   ? 'redfont'
                                              //   : 'greenfont'
                                              items.productData["huigouColor"]
                                          },
                                          [
                                            items.productData["name"] ==
                                              "伦敦金" ||
                                            items.productData["name"] ==
                                              "伦敦钯" ||
                                            items.productData["name"] ==
                                              "伦敦铂"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_f"
                                                        )(
                                                          items.productData[
                                                            "xiaoshou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : items.productData["name"] ==
                                                  "伦敦银" ||
                                                items.productData["name"] ==
                                                  "美元指数"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_fthree"
                                                        )(
                                                          items.productData[
                                                            "xiaoshou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        items.productData[
                                                          "xiaoshou"
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                          ],
                                          2
                                        ),
                                        _c("li", { staticClass: "lastli" }, [
                                          _c(
                                            "div",
                                            {
                                              // new_tuisong_val_before['international'].prices[
                                              class:
                                                //   items['engname']
                                                // ]['high'] <= items.productData['high']
                                                //   ? 'redfont'
                                                //   : 'greenfont'
                                                items.productData["huigouColor"]
                                            },
                                            [
                                              items.productData["name"] ==
                                                "伦敦金" ||
                                              items.productData["name"] ==
                                                "伦敦钯" ||
                                              items.productData["name"] ==
                                                "伦敦铂"
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "changeTwoDecimal_f"
                                                          )(
                                                            items.productData[
                                                              "high"
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : items.productData["name"] ==
                                                    "伦敦银" ||
                                                  items.productData["name"] ==
                                                    "美元指数"
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "changeTwoDecimal_fthree"
                                                          )(
                                                            items.productData[
                                                              "high"
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          items.productData[
                                                            "high"
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              // new_tuisong_val_before['international'].prices[
                                              class:
                                                //   items['engname']
                                                // ]['low'] <= items.productData['low']
                                                //   ? 'redfont'
                                                //   : 'greenfont'
                                                items.productData["huigouColor"]
                                            },
                                            [
                                              items.productData["name"] ==
                                                "伦敦金" ||
                                              items.productData["name"] ==
                                                "伦敦钯" ||
                                              items.productData["name"] ==
                                                "伦敦铂"
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "changeTwoDecimal_f"
                                                          )(
                                                            items.productData[
                                                              "low"
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : items.productData["name"] ==
                                                    "伦敦银" ||
                                                  items.productData["name"] ==
                                                    "美元指数"
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "changeTwoDecimal_fthree"
                                                          )(
                                                            items.productData[
                                                              "low"
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          items.productData[
                                                            "low"
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        ])
                                      ]
                                    : [
                                        _c(
                                          "li",
                                          { staticClass: "redfont" },
                                          [
                                            items.productData["name"] ==
                                              "伦敦金" ||
                                            items.productData["name"] ==
                                              "伦敦钯" ||
                                            items.productData["name"] ==
                                              "伦敦铂"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_f"
                                                        )(
                                                          items.productData[
                                                            "huigou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : items.productData["name"] ==
                                                  "伦敦银" ||
                                                items.productData["name"] ==
                                                  "美元指数"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "changeTwoDecimal_fthree"
                                                        )(
                                                          items.productData[
                                                            "huigou"
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        items.productData[
                                                          "huigou"
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                          ],
                                          2
                                        ),
                                        _c("li", { staticClass: "redfont" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["xiaoshou"]
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("li", { staticClass: "lastli" }, [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(items.productData["high"])
                                            )
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(items.productData["low"])
                                            )
                                          ])
                                        ])
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { background: "transparent", width: "350px" },
          attrs: { "close-icon": "close" },
          on: { close: _vm.noticeClose },
          model: {
            value: _vm.showNotice,
            callback: function($$v) {
              _vm.showNotice = $$v
            },
            expression: "showNotice"
          }
        },
        [
          _c("img", { attrs: { src: _vm.noticeImg, alt: "" } }),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { height: "40px", width: "40px" },
              attrs: {
                src: require("@/assets/images/close_notice.png"),
                alt: ""
              },
              on: { click: _vm.noticeClose }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }